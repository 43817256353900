export const GET_CURRENT_CARD_DATA = `query ($userName: String!, $gameId: String!) {
  list_user_cards(username: $userName, game_id: $gameId){
    message
    data {
      _id
      card
      user_id {
        _id
        username
      }
      organizer_id {
        _id
        username
      }
      game_id {
        _id
        status
      }
    }
    newcall
  }
}
`;

export const FETCH_ALL_CALLED_NUMBERS = `query ($gameId: String!) {
  list_called_numbers (game_id: $gameId) {
    message
    data {
      _id
      num
    }
    newcall
  }
}
`;

export const FETCH_ALL_ORGANISERS = `query{
  list_all_organisers{
    message
    data {
      _id
      username
    }
  }
}
`;

export const FETCH_ALL_GAMES = `query ($orgUsername: String!) {
  list_all_games(org_username: $orgUsername){
    message
    data {
      _id 
      users
      status
    }
  }
}
`;

export const FETCH_ALL_USERS_IN_A_GAME = `query ($gameId: String!) {
  list_all_users_in_a_game(game_id: $gameId){
    message
    data {
      _id
      username
    }
  }
}
`;

export const FETCH_A_GAME_DETAILS = `query ($gameId: String!) {
  list_a_game_details(game_id: $gameId){
    message
    cards {
      _id
      card
      user_id {
        _id
        username
      }
      organizer_id {
        _id
        username
      }
      game_id {
        _id
        status
      }
    }
    winners {
      _id
      line_index
      winner_id {
        _id
        username
      }
    }
  }
}
`;