import React from "react";
import { Grid, Stack } from "@mui/material";

const style = {
    width: "100%",
    padding: "5px 0",
    color: "#ccc",
    borderBottom: "1px solid rgb(77, 77, 77)",
    textTransform: "uppercase",
    userSelect: "none",
    fontWeight: "bold"
};

function PageRouteNavigator({ routes = [] }) {
    const home = [{ name: "home", path: "/" }];
    routes = home.concat(routes);

    return (
        <div style={style}>
            <Grid mt={5} container spacing={1} alignItems="center" justifyContent="center">
                {routes.map((e, i) => (
                    <Grid item key={i}>
                        <Stack direction="row" spacing={1}>
                            <h6
                                onClick={() => window.open(e.path, "_self")}
                                style={{ cursor: "pointer", color: e?.isActive ? "#49de9b" : "" }}
                            >
                                {e?.name}
                            </h6>
                            <h6>
                                {i !== routes.length - 1 ? <i className="fas fa-angle-double-right"/> : ""}
                            </h6>
                        </Stack>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default PageRouteNavigator;
