import { FETCH_ALL_CALLED_NUMBERS } from "graphql";
import { unAuthorizedPost } from "services";

export const listAllCalledNumbers = async (requestData) => {
    try {
        let apiResponse = await unAuthorizedPost("", {
            query: FETCH_ALL_CALLED_NUMBERS,
            variables: requestData,
        });

        if (apiResponse.data.customStatus) {
            let {
                list_called_numbers: { data, newcall },
            } = apiResponse.data.data;

            return { data, newcall };
        } else {
            console.log("Failed to listAllCalledNumbers");
            return false;
        }
    } catch (err) {
        console.log("Unable to listAllCalledNumbers");
        return false;
    }
}