import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { history } from "utils";
import theme from "theme/theme";
import OpenLayout from "layouts/OpenLayout";
import AdminLayout from "layouts/adminLayout";
import Landing from "views/landing";
import Organisers from "views/open/Organisers";
import Games from "views/open/Games";

const Main = () => {
    return (
        <>
            <ThemeProvider theme={theme}>
                {/* <CssBaseline /> */}
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Router history={history}>
                    <Switch>
                        <Route path="/app" render={(props) => <AdminLayout {...props} />} />
                        <Route path="/card" render={(props) => <OpenLayout {...props} />} />
                        <Route path="/organisers" render={(props) => <Organisers {...props} />} />
                        <Route path="/games/:username" render={(props) => <Games {...props} />} />
                        <Route path="/" render={(props) => <Landing {...props} />} />
                        <Redirect from="/" to="/" />
                    </Switch>
                </Router>
            </ThemeProvider>
        </>
    );
};

export default Main;
