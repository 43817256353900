import React, { useState, useEffect } from "react";
import { unAuthorizedPost } from "services";
import { FETCH_A_GAME_DETAILS } from "graphql";
import { Card, Grid, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import PageRouteNavigator from "components/pageRouteNavigator";
import Loader from "components/Loader";
import Avatar from "react-avatar";
import { avatars } from "./Organisers";
import { listAllCalledNumbers } from "api/list-all-called-numbers";

const isWinningLine = (arr) => arr.every((i) => i.includes("marked"));
const BINGO = ["B", "I", "N", "G", "O"];

const LiveGame = ({ gameId }) => {
    const { username } = useParams();
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [winners, setWinners] = useState([]);
    const [gameStatus, setGameStatus] = useState("completed");
    const [newCall, setNewCall] = useState("");
    const [calledNums, setCalledNums] = useState([]);

    useEffect(() => {
        document.title = `Game Details | MYBINGO`
        apiCall({ gameId: gameId })
    }, []);

    useEffect(() => {
        if (gameStatus === "running") {
        const intervalId = setInterval(() => {
                apiCall({ gameId: gameId })
            }, 2000);
            
            return () => clearInterval(intervalId);
        }
    }, [gameStatus, gameId]);

    const apiCall = async (requestData) => {
        let callData = await listAllCalledNumbers({gameId: gameId});
        if (callData) {
            setCalledNums(callData?.data?.reverse());
            setNewCall(callData?.newcall);
        }

        try {
            setLoading(true);

            let apiResponse = await unAuthorizedPost("", {
                query: FETCH_A_GAME_DETAILS,
                variables: requestData,
            });

            if (apiResponse.data.customStatus) {
                let {
                    list_a_game_details: { cards, winners },
                } = apiResponse.data.data;

                if (cards?.length) {
                    setCards(cards)
                    setWinners(winners)
                    setGameStatus(cards[0]?.game_id?.status)
                }
                setLoading(false)
                return;
            } else {
                console.log("Failed to fetch data");
                setLoading(false)
            }
        } catch (err) {
            console.log("Unable to get data");
            setLoading(false)
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <PageRouteNavigator
                routes={[
                    { name: "organisers", path: "/organisers", isActive: false },
                    { name: "games", path: `/games/${username}`, isActive: false },
                    { name: "Game Details", path: "", isActive: true },
                ]}
            />
            <Grid container mt={0.5} mb={5} spacing={2} justifyContent={"center"}>
                <Grid sx={{ color: "white", textAlign: "center" }} item lg={12} xs={12}>
                    <b>Winners</b>
                </Grid>
                {winners?.map((winner, index) => (
                    <Grid sx={{ color: "white", textAlign: "center" }} item lg={4} md={6} sm={11} xs={12} key={index}>
                        <Card sx={{ p: 1, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Card sx={{ width: "70px", height: "70px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#a000b5", fontSize: 30, color: "#fff", borderRadius: 4 }}>
                                    <b>{BINGO[winner?.line_index]}</b>
                                </Card>
                                <Avatar src={avatars[6]?.img} name={winner?.winner_id?.username} size={50} round="20px" />
                                <Stack alignItems={"flex-start"}>
                                    <b className="text-base text-white">{winner?.winner_id?.username}</b>
                                    <small style={{ color: "#c9c9c9" }}>{winner?.winner_id?._id}</small>
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
                {!winners?.length ? 
                <Grid sx={{ color: "white", textAlign: "center" }} item lg={12} xs={12}>
                    <Card sx={{ p: 2, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                        <p>No winners yet</p>
                    </Card>
                </Grid>
                : null}
                <Grid sx={{ color: "white", textAlign: "center" }} item lg={12} xs={12}>
                    <b>Players</b>
                </Grid>
                {cards.map((e, i) => (
                    <Grid item key={i}>
                        <Card sx={{ p: 2, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", borderRadius: 5 }}>
                            <Stack spacing={1}>
                                <Stack>
                                    <table id="bingotable" style={{ width: "280px", height: "250px" }}>
                                        <tbody>
                                            <tr>
                                                <td className="orange">B</td>
                                                {e?.card[0]?.map((num, i) => (
                                                    <td
                                                        style={{ backgroundColor: isWinningLine(e?.card[0]) ? "#9b59b6" : "" }}
                                                        id={num.includes("-marked") ? "marked" : ""}
                                                        key={i}
                                                    >
                                                        {num?.replace("-marked", "")}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td className="orange">I</td>
                                                {e?.card[1]?.map((num, i) => (
                                                    <td
                                                        style={{ backgroundColor: isWinningLine(e?.card[1]) ? "#9b59b6" : "" }}
                                                        id={num.includes("-marked") ? "marked" : ""}
                                                        key={i}
                                                    >
                                                        {num?.replace("-marked", "")}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td className="orange">N</td>
                                                {e?.card[2]?.map((num, i) => (
                                                    <td
                                                        style={{ backgroundColor: isWinningLine(e?.card[2]) ? "#9b59b6" : "" }}
                                                        id={num.includes("-marked") ? "marked" : ""}
                                                        key={i}
                                                    >
                                                        {num?.replace("-marked", "")}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td className="orange">G</td>
                                                {e?.card[3]?.map((num, i) => (
                                                    <td
                                                        style={{ backgroundColor: isWinningLine(e?.card[3]) ? "#9b59b6" : "" }}
                                                        id={num.includes("-marked") ? "marked" : ""}
                                                        key={i}
                                                    >
                                                        {num?.replace("-marked", "")}
                                                    </td>
                                                ))}
                                            </tr>
                                            <tr>
                                                <td className="orange">O</td>
                                                {e?.card[4]?.map((num, i) => (
                                                    <td
                                                        style={{ backgroundColor: isWinningLine(e?.card[4]) ? "#9b59b6" : "" }}
                                                        id={num.includes("-marked") ? "marked" : ""}
                                                        key={i}
                                                    >
                                                        {num?.replace("-marked", "")}
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </Stack>

                                {/* <Stack>
                                    <b>User Id</b>
                                    <>{e._id}</>
                                </Stack> */}
                                <Stack direction={"row"} spacing={2} alignItems="center">
                                    <Avatar src={avatars[6]?.img} name={e?.user_id?.username} size={40} round="20px" />
                                    <Stack width={"60%"}>
                                    <b className="text-base text-white">{e?.user_id?.username}</b>
                                    {/* <small style={{ color: "#c9c9c9" }}>{e?.user_id?._id}</small> */}
                                    </Stack>
                                </Stack>
                                <button
                                    onClick={() => window.open(`/card/${e?.user_id?.username}?game_id=${gameId}`, "_self")}
                                    className="w-full m-4 bg-gradient-to-r from-green-400 to-pink-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                                >
                                    OPEN CARD <i className="fas fa-arrow-right" />
                                </button>
                            </Stack>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <div className="mainopShadow"></div>
            <div className="fab">
                <div className="mainop">
                    <b id="addIcon" className="material-icons">{newCall}</b>
                </div>
                {calledNums.map((e, i) => (
                <div className="minifab op5" key={i}>
                    {e?.num}
                </div>
                ))}
            </div>

            {!cards.length ?
                <Stack pt={20} color="#fff" alignItems="center">
                    No data found
                </Stack>
            : null}
        </>
    );
};

export default LiveGame;
