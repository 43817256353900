import Listing from "views/admin/Listing";
import CardDetails from "views/open/CardDetails";


var openRoutes = [
    {
        path: "/:username",
        name: "Open",
        component: CardDetails,
        layout: "/card",
        visibility: true,
    },
];

var adminRoutes = [
    // {
    //     path: "/listing",
    //     name: "Listing",
    //     component: Listing,
    //     layout: "/app",
    //     visibility: true,
    // },
];


export { openRoutes, adminRoutes };
