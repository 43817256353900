import React, { useState, useEffect } from "react";
import { unAuthorizedPost } from "services";
import { FETCH_ALL_ORGANISERS } from "graphql";
import { Card, Container, Grid, Stack } from "@mui/material";
import PageRouteNavigator from "components/pageRouteNavigator";
import { history } from "utils";
import Loader from "components/Loader";
import Avatar from "react-avatar";

export const avatars = [
    { img: "https://img.freepik.com/premium-vector/gamer-boy-head-with-earphone-illustration_360091-211.jpg"},
    { img: "https://static.vecteezy.com/system/resources/thumbnails/006/988/698/small_2x/man-headphones-mascot-logo-gaming-illustration-vector.jpg"},
    { img: "https://img.freepik.com/premium-vector/gamer-mascot-logo-gaming-badge_10051-451.jpg?w=2000"},
    { img: "https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs/131460353/original/6cae1cc807edb51169a81f9b7d9b370171f85a27/do-a-gaming-avatar.png"},
    { img: "https://designzonic.com/download/wp-content/uploads/2019/06/SamuraiSkullAvatar.png"},
    { img: "https://img.freepik.com/premium-vector/panther-esport-mascot-logo-vector-illustration_562052-450.jpg?w=2000"},
    { img: "https://i.pinimg.com/474x/51/96/b3/5196b34be5aec2079e4b68190299a544.jpg"},
    { img: "https://i.pinimg.com/236x/0d/a2/0b/0da20ba2089ee0a0a6be54472c83535f.jpg"},
    { img: "https://i.pinimg.com/550x/0e/51/7e/0e517eb57cb5a992ef3230b0e0d792af.jpg"},
    { img: "https://pub-static.fotor.com/assets/projects/pages/fae8391aba24406aae09c5961674c939/600w/fotor-d5a2db04fcfb49668320e7dd09dce5a6.jpg"},
    { img: "https://cdn.dribbble.com/users/123607/screenshots/14347925/media/6c7f63c50c1ff5fe5a2a5f00ded9a7b1.gif"},
]

const Organisers = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        document.title = `Organisers | MYBINGO`
        apiCall()
    }, []);

    const apiCall = async () => {
        try {
            setLoading(true);

            let apiResponse = await unAuthorizedPost("", {
                query: FETCH_ALL_ORGANISERS,
            });

            if (apiResponse.data.customStatus) {
                let {
                    list_all_organisers: { data },
                } = apiResponse.data.data;

                if (data?.length) {
                    setData(data)
                }
                setLoading(false)
                return;
            } else {
                console.log("Failed to fetch data");
                setLoading(false)
            }
        } catch (err) {
            console.log("Unable to get data");
            setLoading(false)
        }
    }

    return (
        <>
            <Loader loading={loading}/>
            <Container>
                <div className="w-full container mx-auto p-2">
                    <div className="w-full flex items-center justify-between">
                        <a href="/" className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                            mybingo<span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">.social</span>
                        </a>
                    </div>
                </div>

                <PageRouteNavigator
                    routes={[
                        { name: "organisers", path: "", isActive: true },
                    ]}
                />
                <Grid container mt={2} mb={5} spacing={2}>
                    {data.map((e, i) => (
                        <Grid item lg={4} md={4} sm={6} xs={12} key={i}>
                            <Card sx={{ p: 2, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                                <Stack spacing={2}>
                                    <Stack direction={"row"} spacing={2} size="50">
                                        <Avatar src={avatars[i]?.img} name={e.username} size={80} round="20px" />
                                        <Stack justifyContent="center" alignItems="flex-start">
                                            <b className="text-lg">{e.username}</b>
                                            <small style={{ color: "#c9c9c9" }}>{e._id}</small>
                                        </Stack>
                                    </Stack>
                                    <button
                                        onClick={() => history.push(`/games/${e.username}`)}
                                        className="w-full m-4 bg-gradient-to-r from-green-400 to-pink-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                                    >
                                        VIEW GAMES <i className="fas fa-arrow-right" />
                                    </button>
                                </Stack>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {!data.length ?
                    <Stack pt={20} color="#fff" alignItems="center">
                        No data found
                    </Stack>
                : null}
            </Container>
        </>
    );
};

export default Organisers;
