import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { unAuthorizedPost } from "services";
import { GET_CURRENT_CARD_DATA } from "graphql";
import { listAllCalledNumbers } from "api/list-all-called-numbers";
import { Card, Container, Grid, Stack } from "@mui/material";
// import Loader from "components/Loader";
import "./styles.css";
import PageRouteNavigator from "components/pageRouteNavigator";
import Avatar from "react-avatar";
import { avatars } from "./Organisers";

const isWinningLine = (arr) => arr.every((i) => i.includes("marked"));

const CardDetails = () => {
    const { username } = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const game_id = queryParameters.get("game_id");

    // const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const [newCall, setNewCall] = useState("");
    const [calledNums, setCalledNums] = useState([]);
    const [line1, setLine1] = useState([]);
    const [line2, setLine2] = useState([]);
    const [line3, setLine3] = useState([]);
    const [line4, setLine4] = useState([]);
    const [line5, setLine5] = useState([]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            apiCall({ userName: username, gameId: game_id })
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (username && game_id) {
            document.title = `${username} | MYBINGO`
            apiCall({ userName: username, gameId: game_id })
        }
    }, []);

    const apiCall = async (requestData) => {
        let callData = await listAllCalledNumbers({gameId: game_id});
        if (callData) {
            setCalledNums(callData?.data?.reverse());
            setNewCall(callData?.newcall);
        }

        try {
            // setLoading(true);

            let apiResponse = await unAuthorizedPost("", {
                query: GET_CURRENT_CARD_DATA,
                variables: requestData,
            });

            if (apiResponse.data.customStatus) {
                let {
                    list_user_cards: { data },
                } = apiResponse.data.data;

                if (data?.card?.length) {
                    setData(data)
                    setLine1(data.card[0])
                    setLine2(data.card[1])
                    setLine3(data.card[2])
                    setLine4(data.card[3])
                    setLine5(data.card[4])
                }
                // setLoading(false)
                return;
            } else {
                console.log("Failed to fetch data");
                // setLoading(false)
            }
        } catch (err) {
            console.log("Unable to get data");
            // setLoading(false)
        }
    }

    return (
        <>
            {/* <Loader loading={loading}/> */}
            <div>
                {data?.card ?
                <>
                <Container>
                <div className="w-full container mx-auto p-2">
                    <div className="w-full flex items-center justify-between">
                        <a href="/" className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                            mybingo<span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">.social</span>
                        </a>
                    </div>
                </div>
                <PageRouteNavigator
                    routes={[
                        { name: "organisers", path: "/organisers" },
                        { name: "games", path: `/games/${data?.organizer_id?.username}` },
                        { name: "game details", path: `/games/${data?.organizer_id?.username}?game_id=${game_id}` },
                        { name: "card", path: "", isActive: true },
                    ]}
                />
                <Grid sx={{ color: "white", textAlign: "center" }} container mt={0.5} spacing={1} justifyContent={"center"}>
                    <Grid item lg={3} md={6} sm={11} xs={12}>
                        <Card sx={{ p: 1, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Avatar src={avatars[0]?.img} name={username} size={40} round="20px" />
                                <Stack width={"70%"}>
                                    <b className="text-base text-white">Owner</b>
                                    <small className="text-white">{username}</small>
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item lg={3} md={6} sm={11} xs={12}>
                        <Card sx={{ p: 1, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                                <Avatar src={avatars[2]?.img} name={username} size={40} round="20px" />
                                <Stack width={"70%"}>
                                    <b className="text-base text-white">Organized by</b>
                                    <small className="text-white">{data?.organizer_id?.username}</small>
                                </Stack>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item lg={3} md={6} sm={11} xs={12}>
                        <Card sx={{ p: 1, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                            <Stack justifyContent={"center"}>
                                    <b className="text-base text-white">Game Id</b>
                                    <small style={{ color: "#c9c9c9" }}>{data?.game_id?._id}</small>
                            </Stack>
                        </Card>
                    </Grid>
                    <Grid item lg={3} md={6} sm={11} xs={12}>
                        <Card sx={{ p: 1, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                            <Stack justifyContent={"center"}>
                                    <b className="text-base text-white">Card Id</b>
                                    <small style={{ color: "#c9c9c9" }}>{data?._id}</small>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
                    <div className="card-container">
                        <div>
                            <table id="bingotable">
                                <tbody>
                                    <tr>
                                        <td className="orange">B</td>
                                        {line1?.map((num, i) => (
                                            <td
                                                style={{ backgroundColor: isWinningLine(line1) ? "#9b59b6" : "" }}
                                                id={num.includes("-marked") ? "marked" : ""}
                                                key={i}
                                            >
                                                {num?.replace("-marked", "")}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="orange">I</td>
                                        {line2?.map((num, i) => (
                                            <td
                                                style={{ backgroundColor: isWinningLine(line2) ? "#9b59b6" : "" }}
                                                id={num.includes("-marked") ? "marked" : ""}
                                                key={i}
                                            >
                                                {num?.replace("-marked", "")}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="orange">N</td>
                                        {line3?.map((num, i) => (
                                            <td
                                                style={{ backgroundColor: isWinningLine(line3) ? "#9b59b6" : "" }}
                                                id={num.includes("-marked") ? "marked" : ""}
                                                key={i}
                                            >
                                                {num?.replace("-marked", "")}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="orange">G</td>
                                        {line4?.map((num, i) => (
                                            <td
                                                style={{ backgroundColor: isWinningLine(line4) ? "#9b59b6" : "" }}
                                                id={num.includes("-marked") ? "marked" : ""}
                                                key={i}
                                            >
                                                {num?.replace("-marked", "")}
                                            </td>
                                        ))}
                                    </tr>
                                    <tr>
                                        <td className="orange">O</td>
                                        {line5?.map((num, i) => (
                                            <td
                                                style={{ backgroundColor: isWinningLine(line5) ? "#9b59b6" : "" }}
                                                id={num.includes("-marked") ? "marked" : ""}
                                                key={i}
                                            >
                                                {num?.replace("-marked", "")}
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                            {/* <p className="numtxt">Current Call: {newCall}</p> */}
                            <div className="numlist">
                                <b>Called Numbers</b>
                                <Grid container justifyContent={"center"}>
                                {calledNums.map((e, i) => (
                                    <Grid item key={i}>
                                    <div className="nums" style={{ backgroundColor: e.num === newCall ? "#a000b5" : "" }}>{e.num}</div>
                                    </Grid>
                                ))}
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <br/>
                    {/* <div className="p-2" style={{backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff"}}>
                    <Stack direction={{lg:"row", md: "row", xs: "column"}} justifyContent={"space-evenly"}>
                        <Stack>
                            <strong>Owner id</strong>
                            <h5>{username}</h5>
                        </Stack>
                        <Stack>
                            <strong>Card id</strong>
                            <h5>{data?._id}</h5>
                        </Stack>
                        <Stack>
                            <strong>Game id</strong>
                            <h5>{data?.game_id?._id}</h5>
                        </Stack>
                        <Stack>
                            <strong>Organized by</strong>
                            <h5>{data?.organizer_id?.username}</h5>
                        </Stack>
                    </Stack>
                    </div> */}
                    </Container>
                     </>:
                    <div style={{ color: "#fff" }}>
                        no data found
                    </div>}
            </div>
        </>
    );
};

export default CardDetails;
