import React, { useState, useEffect } from "react";
import { unAuthorizedPost } from "services";
import { FETCH_ALL_GAMES } from "graphql";
import { Card, Container, Grid, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import PageRouteNavigator from "components/pageRouteNavigator";
import Loader from "components/Loader";
// import Players from "./Players";
import LiveGame from "./LiveGame";
import Avatar from "react-avatar";

const imgs = [
    {img: "https://i.pinimg.com/736x/df/72/d5/df72d51685e99a265ad186bada408e27.jpg"},
    {img: "https://img.freepik.com/free-vector/modern-professional-knights-logo-mascot-game-design-template_1258-29033.jpg?w=740&t=st=1677272097~exp=1677272697~hmac=34e39743c60a712b6f1fa2253123ee44d17865e67962a44e5e34491b5616b0bf"}
]

const Games = () => {
    const { username } = useParams();
    const queryParameters = new URLSearchParams(window.location.search);
    const game_id = queryParameters.get("game_id");

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (game_id) {
            setPage(2);
        } else {
            document.title = `Games | MYBINGO`
            apiCall({ orgUsername: username })
        }
    }, []);

    const apiCall = async (requestData) => {
        try {
            setLoading(true);

            let apiResponse = await unAuthorizedPost("", {
                query: FETCH_ALL_GAMES,
                variables: requestData,
            });

            if (apiResponse.data.customStatus) {
                let {
                    list_all_games: { data },
                } = apiResponse.data.data;

                if (data?.length) {
                    setData(data)
                }
                setLoading(false)
                return;
            } else {
                console.log("Failed to fetch data");
                setLoading(false)
            }
        } catch (err) {
            console.log("Unable to get data");
            setLoading(false)
        }
    }

    return (
        <>
            <Loader loading={loading}/>
            <Container>
                <div className="w-full container mx-auto p-2">
                    <div className="w-full flex items-center justify-between">
                        <a href="/" className="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl">
                            mybingo<span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500">.social</span>
                        </a>
                    </div>
                </div>

                {page === 1 ? (
                <>
                <PageRouteNavigator
                    routes={[
                        { name: "organisers", path: "/organisers", isActive: false },
                        { name: "games", path: "", isActive: true },
                    ]}
                />
                <Grid container mt={2} mb={5} spacing={2}>
                    {data.map((e, i) => (
                        <Grid item lg={4} md={4} sm={6} xs={12}key={i}>
                            <Card sx={{ p: 2, textAlign: "center", backgroundColor: "rgb(97, 134, 255, 0.2)", color: "#fff", borderRadius: 5 }}>
                                <Stack spacing={1}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Avatar src={imgs[0]?.img} name={e.username} size={80} round="20px" />
                                        <Stack justifyContent="center" alignItems="flex-start">
                                            <b className="text-base">Game Id</b>
                                            <small style={{ color: "#c9c9c9" }}>{e._id}</small>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent="space-between" p={1}>
                                        <Stack direction={"row"} spacing={1} alignItems="center">
                                            <i className="fa fa-users" style={{color: "#49de9b"}}/>
                                            <b className="text-base">{e.users.length}</b>
                                        </Stack>
                                        <Stack direction={"row"} spacing={1} alignItems="center">
                                            {e.status === "running" ? <i className="fa fa-circle" style={{color: "#00ff37"}}/> : <i className="fa fa-check-circle-o" style={{color: "skyblue"}}/>}
                                            <p>{e.status}</p>
                                        </Stack>
                                    </Stack>
                                    <button
                                        onClick={() => window.open(`/games/${username}?game_id=${e._id}`, "_self")}
                                        className="w-full m-4 bg-gradient-to-r from-green-400 to-pink-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                                    >
                                        OPEN <i className="fas fa-arrow-right" />
                                    </button>
                                </Stack>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {!data.length ?
                    <Stack pt={20} color="#fff" alignItems="center">
                        No data found
                    </Stack>
                : null}
                </>

                ) : page === 2 ? (
                    <LiveGame gameId={game_id}/>
                ) : null}
            </Container>
        </>
    );
};

export default Games;
