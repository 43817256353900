import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

function Loader({ loading = false }) {
    return (
        <Backdrop open={loading} style={{ zIndex: 999 }}>
            <CircularProgress style={{ color: "blue" }} />
        </Backdrop>
    )
}

export default Loader;